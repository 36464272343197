import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import { ImageText, TeamBlock, PartnerLogo } from "../components/modular-blocks"
import ProjectTiles from "../components/project-tiles"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const GlobalStyle = createGlobalStyle`



.headroom--unfixed{
  color: #EFEDED;
  .hamburger.inactive{
    span{
      background-color: #EFEDED;
    }
  }
  
  .header-logo-mark, .header-word-mark{
      path{
        fill: #EFEDED;
      }
    }
  }

  





  

`

const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
      datoCmsHomepage {
        slogan
        introduction
        featureImage {
          gatsbyImageData
          format
          url
          alt
          video {
            thumbnailUrl(format: gif)
          }
        }
        contentBlocks {
          ... on DatoCmsPartnerLogo {
            model {
              apiKey
            }
            partners {
              title
              link
              logo {
                gatsbyImageData(imgixParams: { w: "400", h: "400", fit: "crop" })
              }
            }
          }

          ... on DatoCmsTeamBlock {
            model {
              apiKey
            }
            id
            projectTeam {
              id
              name
              profile {
                gatsbyImageData(imgixParams: { w: "1000", h: "1300", fit: "crop" })
              }
              jobTitle
              description
            }
          }

          ... on DatoCmsBlockImageText {
            model {
              apiKey
            }
            id
            text
            imageOnLeft

            image {
              gatsbyImageData
              format
              url
              alt
              video {
                thumbnailUrl(format: gif)
              }
            }
          }
        }
      }
    }
  `)

  const Hero = styled.div`
    height: calc(90vh - 100px);
    min-height: 400px;
    position: relative;
    object-fit: cover;

    video,
    img {
      object-fit: cover;
      object-position: center;
      height: calc(90vh - 100px);
      min-height: 400px;
      width: 100vw;
      max-width: 2000px;
    }
    h1 {
      position: absolute;
      top: 45%;
      z-index: 0;
    }
  `

  const heroImage = getImage(data.datoCmsHomepage.featureImage)
  const heroBgImage = convertToBgImage(heroImage)

  return (
    <Layout>
      <GlobalStyle />

      <BackgroundImage {...heroBgImage}>
        <Hero className="flex items-center justify-center text-light">
          <h1 className="text-lg text-center px-12 ">{data.datoCmsHomepage.slogan}</h1>
        </Hero>
      </BackgroundImage>

      <div className="wrap bg-dark text-swirl py-20">
        <div
          className="fluid  sm:w-3/4 lg:w-2/3 xl:w-1/2 sm:text-center"
          dangerouslySetInnerHTML={{ __html: data.datoCmsHomepage.introduction }}
        />

        <ProjectTiles title="Recent Builds" />

        {data.datoCmsHomepage.contentBlocks.map(block => (
          <>
            {block.model.apiKey === "block_image_text" && <ImageText map={block} />}
            {block.model.apiKey === "team_block" && <TeamBlock map={block} />}
            {block.model.apiKey === "partner_logo" && <PartnerLogo map={block} />}
          </>
        ))}
      </div>
    </Layout>
  )
}

export default HomePage
