import React from "react"
import Media from "./media"
import Reveal from "react-reveal/Reveal"
import { Link } from "gatsby"

export const ImageText = props => {
  return (
    <div className="fluid md:grid-cols-2 my-20 lg:my-32 items-center">
      <div className={props.map.imageOnLeft ? "md:order-0 " : "md:order-1"}>
        <Media asset={props.map.image} />
      </div>
      <div className="md:p-10 lg:p-20" dangerouslySetInnerHTML={{ __html: props.map.text }} />
    </div>
  )
}

export const TeamBlock = props => {
  return (
    <div className="fluid md:grid-cols-3 pt-10 lg:pt-20 ">
      {props.map.projectTeam.map(person => (
        <div className="person mb-10">
          <Media asset={person.profile} />
          <h2 className="p my-10 font-medium">
            {person.name} – {person.jobTitle}
          </h2>

          <div dangerouslySetInnerHTML={{ __html: person.description }} />
        </div>
      ))}
    </div>
  )
}
export const PartnerLogo = props => {
  return (
    <div className="fluid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-5/6 lg:pt-20">
      {props.map.partners.map(partner => (
        <Reveal effect="fadeInUp">
          {partner.link ? (
            //if contains URL
            <Link to={partner.link} title={partner.title} target="_blank">
              <Media asset={partner.logo} />
            </Link>
          ) : (
            // else, no URL
            <Media asset={partner.logo} />
          )}
        </Reveal>
      ))}
    </div>
  )
}
